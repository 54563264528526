import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <link rel="icon" href="images/djd.png" type="image/x-icon"/>
        <img src="images/dd.png" className="App-logo" alt="logo" />
        <p>
          Na stránke aktuálne prebieha údržba.
        </p>
        <p>
          V prípade potreby ma môžete kontaktovať telefonicky alebo e-mailom.
          <br/>
          +421 907 437 186
          <br/>
          djdali@djdali.sk
        </p>
      </header>
    </div>
  );
}

export default App;
